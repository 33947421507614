import React, {Fragment} from 'react';

import isNumber from '@renofi/utilities/src/isNumber';
import {basic20} from '@renofi/theme';

import Checkbox from '../../../Checkbox';
import Flex from '../../../Flex';
import Text from '../../../Text';
import Option from '../Option';

import {OptionsPropTypes} from './propTypes';
import {generateKey} from './utils';
import {OptionLabel} from './styled';

function Options(props) {
  const {
    multi,
    value: selected,
    onSelect,
    options = [],
    keyProp,
    optionCss,
    focusIndex,
    customOptionComponent,
  } = props;

  return (
    <Fragment>
      {options.map((option) => {
        const {depth, disabled, label, prefix, value} = option;

        const castedValue = isNumber(value) ? Number(value) : value;
        const key = generateKey({label, value, keyProp});
        const active =
          multi && Array.isArray(selected)
            ? selected.includes(castedValue)
            : selected === castedValue;
        const optFromFlat = options[focusIndex];
        const focus = optFromFlat === option;
        const CustomOptionComponent = customOptionComponent;

        if (customOptionComponent)
          return <CustomOptionComponent {...option} onClick={onSelect} />;

        return (
          <Fragment key={key}>
            {prefix && (
              <Text py={2} px={depth + 1} color={basic20} fontSize={14}>
                {prefix}
              </Text>
            )}
            <Option
              style={optionCss}
              depth={depth}
              key={key}
              focus={focus}
              active={active}
              onClick={(e) => !disabled && onSelect(value, e)}>
              <Flex alignItems="center">
                {multi && (
                  <Checkbox
                    small
                    css={{
                      position: 'relative',
                      top: 4,
                      left: 2,
                    }}
                    mr={2}
                    disabled={disabled}
                    name={value}
                    value={value ?? ''}
                    checked={active}
                    onChange={(v, e) => e.stopPropagation()}
                  />
                )}
                <OptionLabel data-testid={value} title={label}>
                  {label}
                </OptionLabel>
              </Flex>
            </Option>
          </Fragment>
        );
      }, options)}
    </Fragment>
  );
}

Options.propTypes = OptionsPropTypes;

export default Options;

import {gql} from '@apollo/client';

export default gql`
  mutation updateLenderLoanApplication($lenderLoanApplicationId: ID!, $attributes: LenderLoanApplicationInputObject!) {
    updateLenderLoanApplication(lenderLoanApplicationId: $lenderLoanApplicationId, attributes: $attributes) {
      lenderLoanApplication {
        id
        loanNumber
        createdAt
        updatedAt
      }
    }
  }
`;

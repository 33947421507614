import {ApolloLink} from '@apollo/client';
import {omit} from 'lodash';
import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';

const dataDogLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    const context = operation.getContext();
    const data = {
      request: omit(operation, ['query', 'extensions']),
      response: result,
      requestHeaders: {
        ...(context?.headers || {}),
        Authorization: context?.headers?.Authorization?.slice(-10),
      },
    };

    datadogLogs.logger.info(
      `GraphQl ${operation.query?.definitions[0]?.operation}: ${operation?.operationName}`,
      data,
    );
    datadogRum.addAction(
      `GraphQl/${operation.query?.definitions[0]?.operation}-${operation?.operationName}`,
    );
    return result;
  });
});

export default dataDogLink;

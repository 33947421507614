import {documentWithAllProps} from '../document';

import creditLiability from './creditLiability';

export default `
coborrowerOwned
documentIds
documents {
  ${documentWithAllProps}
}
creditLiabilities {
  ${creditLiability}
}
existingMortgageLoan
homeownerMonthlyInsurance
homeownersAssociationFee
homeownersAssociationFeesExist
homeownersAssociationFeesIncluded
id
monthlyProfitOrLoss
mortgageMonthlyPayments
mortgageAccountNumber
mortgageBalance
mortgageCompany
mortgageCompanyOther
mortgageInterestRate
mortgageMonthlyInsurance
mortgageMonthlyPayment
mortgageStatementDate
otherMonthlyExpenses
primaryBorrowerOwned
propertyKind
propertyMonthlyTaxes
propertyStructureType
propertyUseType
taskId
taxAndInsuranceIncluded
yearPurchased
`;

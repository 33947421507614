import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {PatternFormat} from 'react-number-format';
import {isValid, parse} from 'date-fns';
import {isNil} from 'ramda';
import {noop} from 'lodash';

import {basic20} from '@renofi/theme';
import {isIsoDate} from '@renofi/utilities/src/dates';
import {formatIsoDate} from '@renofi/utilities/src/dates/dateTimeDiff';

import FieldFrame from '../FieldFrame';
import FieldInput from '../FieldInput/Component';

const MASK = '_';

const SimpleDateField = ({
  className,
  format = 'MM/yyyy',
  leftIcon = 'calendar',
  mask = MASK,
  placeholder,
  value,
  name,
  onChange,
  small,
  onBlur = noop,
  onFocus = noop,
  validations = [],
  onValidate = () => {},
  showValidation,
  emptyAsNull,
  styles = {},
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [displayValue, setDisplayValue] = useState(value);
  const [, setIsDirty] = useState(!isNil(value));
  const [validationErr, setValidationErr] = useState(null);

  function onValueChange({target}) {
    const newValue = target.value;
    const isValueValid = isValid(parse(newValue, format, new Date()));

    setIsDirty(newValue !== placeholder);

    if (emptyAsNull && newValue === '') return onChange(null, isValueValid);
    onChange(newValue, isValueValid);
  }

  useEffect(() => {
    const isIso = isIsoDate(value);

    // If the user input includes the "mask", we're still typing!
    if (!isIso || !value || (value && value.includes(MASK))) {
      return setDisplayValue(value);
    }
    setDisplayValue(formatIsoDate(value));
  }, [format, value]);

  const blurHandler = useCallback((event) => {
    setFocus(false);
    onBlur && onBlur(event);
  });

  const focusHandler = useCallback((event) => {
    setFocus(true);
    onFocus && onFocus(event);
  });

  const validationHandler = (err, name) => {
    setValidationErr(err);
    onValidate(err, name);
  };

  return (
    <FieldFrame
      name={name}
      inputStyles={{paddingRight: '0 !important'}}
      className={className}
      inputValue={displayValue}
      placeholder={placeholder}
      small={small}
      {...props}
      leftIcon={small ? null : leftIcon}
      rightIcon={small ? 'calendar' : null}
      focused={focus}
      error={showValidation ? validationErr : props.error}
      showIconDivider>
      <PatternFormat
        css={{
          color: `${value ? 'inherit' : basic20}!important`,
          ...styles,
        }}
        showIconDivider
        name={name}
        id={name}
        leftIcon="calendar"
        rightIcon="calendar"
        value={displayValue}
        onChange={onValueChange}
        format={format.replace(/\w/gi, '#')}
        mask={mask}
        onBlur={blurHandler}
        onFocus={focusHandler}
        placeholder={placeholder}
        active
        customInput={FieldInput}
        validations={validations}
        onValidate={validationHandler}
        showValidation={showValidation}
        passFullEvent
        {...props}
      />
    </FieldFrame>
  );
};

SimpleDateField.propTypes = {
  format: PropTypes.string,
  leftIcon: PropTypes.string,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validations: PropTypes.array,
  onValidate: PropTypes.func,
  showValidation: PropTypes.bool,
  emptyAsNull: PropTypes.bool,
  styles: PropTypes.object,
};

export default SimpleDateField;

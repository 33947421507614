import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';

import wrapWith from '@renofi/utilities/src/wrapWith';
import {basic03, basic10, basic80, border} from '@renofi/theme/src/colors';
import {mediaQuery} from '@renofi/theme';

import Flex from '../Flex';

import arrow from './arrow.svg';
import gradientIcon from './gradient-bg.png';
import sort from './sort.svg';
import swipeIcon from './swipe.svg';

const responsiveStyle = {
  maxWidth: '34vw',
  position: 'sticky',
  left: 0,
  zIndex: 1,
  transform: 'scale(1)',
  background: [`white url(${gradientIcon}) repeat-y`, 'none'],
  backgroundPositionX: 'right',
  backgroundSize: 7,
  paddingRight: 25,
};

const scale = keyframes`
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.5);
  }
`;

const swipeIndication = {
  display: ['block', 'none'],
  transform: 'scale(1.5)',
  zIndex: 2,
  animation: `${scale} 0.8s infinite`,
  content: `" "`,
  width: 110,
  height: 60,
  background: `url(${swipeIcon}) no-repeat`,
  position: 'absolute',
  left: 'calc(50vw - 55px)',
  top: 'calc(50% - 25px)',
  pointerEvents: 'none',
};

export const Table = styled.table(
  {
    width: '100%',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    '& th': {
      color: basic80,
      fontWeight: 300,
      letterSpacing: 0.4,
      lineHeight: '17px',
      textAlign: 'left',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: `1px solid ${border}`,
      padding: 8,
      ':first-of-type': {
        paddingLeft: 16,
      },
      ':last-of-type': {
        paddingRight: 16,
      },
    },
    '& td': {
      color: '#999999',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: `1px solid ${border}`,
      padding: 8,
      ':first-of-type': {
        paddingLeft: 16,
      },
      ':last-of-type': {
        paddingRight: 16,
      },
    },
    '& tr:last-of-type td': {
      borderBottom: 'none',
    },
  },
  ({
    bordered,
    borderless,
    condensed,
    hover,
    css,
    responsive,
    showSwipeIcon,
    tableLayout,
    fontSize,
    white,
  }) =>
    mediaQuery({
      tableLayout,
      '& thead th': {
        height: condensed ? 48 : 60,
        fontSize: fontSize || 14,
        lineHeight: fontSize ? `${fontSize + 2}px` : '17px',
        backgroundColor: white ? 'white' : basic03,
        borderTop: `solid 1px ${basic10}`,
      },
      '& tbody td': {
        height: condensed ? 44 : 60,
        fontSize: fontSize || 14,
        lineHeight: fontSize ? `${fontSize + 2}px` : '17px',
      },
      'tr th:first-of-type, tr td:first-of-type': {
        ...(responsive ? responsiveStyle : {}),
      },
      ...(borderless
        ? {
            '& td': {border: 'none'},
            '& th': {border: 'none'},
          }
        : {
            border: `1px solid ${bordered ? border : 'transparent'}`,
            '& th': {
              borderLeft: `1px solid ${bordered ? border : 'transparent'}`,
            },
            '& td': {
              borderLeft: `1px solid ${bordered ? border : 'transparent'}`,
            },
            '& td:first-of-type': {
              borderLeft: 'none',
            },
          }),
      ...(hover
        ? {
            '& tbody tr:hover': {
              cursor: 'pointer',
              backgroundColor: 'rgba(128,208,255,0.2)',
            },
          }
        : {}),
      ':before': {
        ...(showSwipeIcon ? swipeIndication : {}),
      },
      ...css,
    }),
);

export const HeadCell = wrapWith(['active', 'reverse', 'sortable'])(
  styled.th({}),
)(
  styled.span({display: 'inline-block'}, ({active, reverse, sortable}) => ({
    ...(sortable && !active
      ? {
          paddingRight: 24,
          cursor: 'pointer',
          position: 'relative',
          userSelect: 'none',
          ':after': {
            content: '""',
            display: 'inline-block',
            width: 16,
            height: 16,
            position: 'absolute',
            right: 3,
            top: '50%',
            marginTop: -8,
            backgroundImage: `url('${sort}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '16px 16px',
            backgroundPosition: 'right center',
          },
        }
      : {}),
    ...(sortable && active
      ? {
          paddingRight: 24,
          cursor: 'pointer',
          position: 'relative',
          userSelect: 'none',
          ':after': {
            content: '""',
            display: 'inline-block',
            width: 16,
            height: 16,
            position: 'absolute',
            right: 3,
            top: '50%',
            marginTop: -8,
            backgroundImage: `url('${arrow}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '16px 16px',
            backgroundPosition: 'right center',
            ...(reverse ? {} : {transform: 'scaleY(-1)'}),
          },
        }
      : {}),
  })),
);

export const Wrapper = styled.div(({responsive, overflow, position}) => ({
  position: position || 'relative',
  width: '100%',
  overflowX: responsive ? 'scroll' : 'auto',
  ...(overflow ? {overflow} : {}),
}));

export const Cover = styled(Flex)({
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.6)',
  width: '100%',
  left: 0,
  top: 60,
  height: 'calc(100% - 60px)',
});

export const Placeholder = styled(Flex)({}, ({height}) => ({
  background: basic03,
  justifyContent: 'center',
  alignItems: 'center',
  height: height || 60,
}));

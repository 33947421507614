import React, {Fragment, Suspense, useCallback, useMemo} from 'react';

import PropTypes from 'prop-types';
import {Redirect, Route, Switch, useHistory, useParams} from 'react-router-dom';
import {Flex, Text} from 'rebass';

import {
  Back,
  Container,
  Loader,
  Navbar,
  Tabs,
} from '@renofi/components-internal';
import {asyncComponent} from '@renofi/utilities';
import {canManageLenderUsers} from '@renofi/utilities/src/permissions';
import {basic80} from '@renofi/theme';

import {useCurrentLender} from '../../hooks';

const INTERNAL_TABS = {
  whitelist: 'IP Whitelist',
  reports: 'Reports',
  properties: 'Properties',
};

const Application = asyncComponent(
  () => import(/* webpackChunkName: "settings-application" */ '../Application'),
);

const Notifications = asyncComponent(
  () =>
    import(/* webpackChunkName: "settings-notifications" */ '../Notifications'),
);

const MembersList = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "settings-team-list" */ '../Team/components/MembersList'
    ),
);

const TeamMember = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "settings-team-details" */ '../Team/components/Member'
    ),
);

const Whitelist = asyncComponent(
  () => import(/* webpackChunkName: "whitelist" */ '../Whitelist'),
);

const Raas = asyncComponent(
  () => import(/* webpackChunkName: "raas" */ '../Raas'),
);

const Reports = asyncComponent(
  () => import(/* webpackChunkName: "lender-report" */ '../Reports/Reports'),
);

const Properties = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "lender-properties" */ '../Properties/Properties'
    ),
);

const Settings = ({basePath, baseRoute, impersonation, mode}) => {
  const {tab} = useParams();
  const history = useHistory();
  const {lender, loading, user} = useCurrentLender();

  const tabs = useMemo(() => {
    const canManageUsers = canManageLenderUsers(user);

    return {
      application: 'Online Application',
      ...(canManageUsers ? {team: 'Team'} : {}),
      notifications: 'Notifications',
      ...(mode === 'internal' ? INTERNAL_TABS : {}),
    };
  }, [mode, user]);

  const onChangeTab = useCallback(
    (newTab) => {
      history.push(`${basePath}/${newTab}`);
    },
    [basePath],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Navbar
        fixed
        narrow
        top={impersonation ? 130 : 70}
        height={60}
        variant="secondary"
        justifyContent="center"
        css={{zIndex: 1001}}>
        <Text color={basic80} fontSize={25}>
          {mode === 'internal' ? (
            <>
              <Back to="/lenders" />
              {lender?.name} Settings
            </>
          ) : (
            'Settings'
          )}
        </Text>
      </Navbar>
      <Navbar
        fixed
        narrow
        top={impersonation ? 190 : 130}
        height={60}
        variant="crystal"
        alignItems="flex-end">
        <Tabs active={tab} items={tabs} onChange={onChangeTab} />
      </Navbar>
      <Flex flexDirection="column" alignItems="center">
        <Container narrow mt={24}>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                exact
                path={`${baseRoute}/application`}
                render={(props) => (
                  <Application lenderId={lender?.id} mode={mode} {...props} />
                )}
              />
              <Route
                exact
                path={`${baseRoute}/team`}
                render={(props) => (
                  <MembersList {...{basePath, baseRoute, mode, ...props}} />
                )}
              />
              <Route
                exact
                path={`${baseRoute}/team/new/:tab`}
                render={(props) => (
                  <TeamMember {...{basePath, baseRoute, mode, ...props}} />
                )}
              />
              <Route
                path={`${baseRoute}/team/:memberId/:tab`}
                render={(props) => (
                  <TeamMember {...{basePath, baseRoute, mode, ...props}} />
                )}
              />
              <Route
                exact
                path={`${baseRoute}/notifications`}
                render={(props) => (
                  <Notifications {...{basePath, baseRoute, mode, ...props}} />
                )}
              />
              <Route exact path={`${baseRoute}/reports`}>
                <Reports
                  mode={mode}
                  allowUploads={lender?.lenderProvidedReportsEnabled}
                />
              </Route>
              <Route
                exact
                path={`${baseRoute}/properties`}
                component={Properties}
              />
              <Route
                exact
                path={`${baseRoute}/whitelist`}
                render={(props) => (
                  <Whitelist {...{basePath, baseRoute, mode, ...props}} />
                )}
              />
              <Route exact path={`${baseRoute}/raas`} component={Raas} />
              <Redirect to={`${basePath}/application`} />
            </Switch>
          </Suspense>
        </Container>
      </Flex>
    </Fragment>
  );
};

Settings.propTypes = {
  impersonation: PropTypes.bool,
  basePath: PropTypes.string.isRequired,
  baseRoute: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
};

export default Settings;

import React from 'react';

import PropTypes from 'prop-types';

import formatMoney from '@renofi/utilities/src/formatMoney';

import FieldFrame from './FieldFrame';

const FakeField = ({children, money, placeholder, value, ...props}) => {
  const content = money
    ? value
      ? formatMoney(Number(value), {fractional: false, style: 'decimal'})
      : null
    : value;
  return (
    <FieldFrame
      small
      inactive={Boolean(children)}
      leftIcon={money ? 'dollar' : undefined}
      {...props}>
      {children || content}
    </FieldFrame>
  );
};

FakeField.propTypes = {
  children: PropTypes.node,
  money: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FakeField;

import {gql} from '@apollo/client';

export default gql`
  mutation submitLenderProvidedReport(
    $lenderId: ID!
    $objectName: StorageObjectName!
  ) {
    submitLenderProvidedReport(lenderId: $lenderId, objectName: $objectName) {
      success
    }
  }
`;

import creditLiability from './creditLiability';

export default `
city
coborrowerOwned
county
id
owner
primaryBorrowerOwned
propertyStructureType
state
streetAddressOne
streetAddressTwo
taskId
zipCode
soldDate
`;
